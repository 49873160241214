import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
const getAccessToken = (state) => {
  // Use state to access the access token from the Redux store
  return state.auth.accessToken;
};

export const Api = createApi({
  reducerPath: "public",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_ARTOGENIA_API }),
  refetchOnReconnect: true,
  tagTypes: [
    "explore",
    "SingleArt",
    "HomeApi",
    "exploreArtists",
    "exploreOwners",
    "contests",
  ],
  endpoints: (builder) => ({
    getArts: builder.mutation<any, any>({
      query: ({ pageNo, pageSize, filters, domain }) => {
        var body: any = {
          pageNo,
          pageSize,
          sort: {
            [filters.sortBy.key]: filters.sortBy.value,
          },
          filter: [],
        };
        if (filters?.onlySale === "true") {
          const sale = {
            key: "offer.price.amount",
            operator: "EXISTS",
          };
          const offerType = {
            key: "offer.type",
            value: `${filters?.fixedPrice == "true" ? 0 : ""}${
              filters?.fixedPrice == "true" && filters?.timedAuction == "true"
                ? ","
                : ""
            }${filters?.timedAuction == "true" ? "1" : ""}`,
            operator: "IN",
          };

          // Add filters to the body
          body.filter.push(sale);
          body.filter.push(offerType);
        } else {
          // Remove the filters related to sale and offerType
          body.filter = body.filter.filter(
            (item) =>
              item.key !== "offer.price.amount" && item.key !== "offer.type"
          );
        }

        if (filters.genre.length > 0) {
          const genre = {
            key: "genres._id",
            value: `${filters.genre.join(",")}`,
            operator: "IN",
          };
          body.filter.push(genre);
        } else {
          //  remove genere to filter
          body.filter = body.filter.filter((item) => item.key !== "genres._id");
        }

        const addDimensionFilters = (dimension, paramName) => {
          if (filters?.dimensions?.[dimension]?.length > 0) {
            const dimensions = {
              key: paramName,
              value: `${filters.dimensions[dimension][0]},${filters.dimensions[dimension][1]}`,
              operator: "BETWEEN",
            };

            // Add the dimension filter to the body.filter array
            body.filter.push(dimensions);
          } else {
            // Remove the dimension filter if it exists
            body.filter = body.filter.filter((item) => item.key !== paramName);
          }
        };

        addDimensionFilters("width", "dimensions.width");
        addDimensionFilters("height", "dimensions.height");
        addDimensionFilters("depth", "dimensions.depth");
        addDimensionFilters("weight", "dimensions.weight");

        if (filters?.price?.max) {
          const price = {
            key: "offer.price.amount",
            value: `${filters.price.min},${filters.price.max}`,
            operator: "BETWEEN",
          };

          // Add price filter to the body
          body.filter.push(price);
        } else {
          // Remove the price filter if it exists
          body.filter = body.filter.filter(
            (item) => item.key !== "offer.price.amount"
          );
        }

        if (filters?.artist !== "all") {
          // queryParams.push(`filters[artist]=${filters.artist.toLowerCase()}`);
          const artist = {
            key: "artists._id",
            value: filters.artist,
            operator: "EQUAL",
          };
          console.log("🚀 ~ artist:", artist);
          body.filter.push(artist);
        } else {
          body.filter = body.filter.filter(
            (item) => item.key !== "artists._id"
          );
        }
        if (filters?.contests !== "all") {
          const contests = {
            key: "contests_list._id",
            value: filters?.contests?.id,
            operator: "EQUAL",
          };
          console.log("🚀 ~ contests:", contests);
          body.filter.push(contests);
        } else {
          body.filter = body.filter.filter(
            (item) => item.key !== "contests_list._id"
          );
        }
        return {
          url: `/products/fetch`,
          method: "POST",
          headers: {
            "x-domain-name":
              domain == "academy" ? "art,carpet,antique,handcraft" : domain,
          },
          body,
        };
      },
      invalidatesTags: ["explore"],
    }),
    getArtists: builder.mutation<any, any>({
      query: ({ pageNo, pageSize, filters, validated = "true" }) => {
        var body: any = {
          pageNo,
          pageSize,
          filter: [],
          sort: {
            'created_at': "DESC",
          },
        };
        if (filters?.price?.max) {
          const price = {
            key: "offer.price.amount",
            value: `${filters.price.min},${filters.price.max}`,
            operator: "BETWEEN",
          };

          // Add price filter to the body
          body.filter.push(price);
        } else {
          // Remove the price filter if it exists
          body.filter = body.filter.filter(
            (item) => item.key !== "offer.price.amount"
          );
        }
        return {
          url: `/artists/fetch`,
          method: "POST",

          body,
        };
      },
      invalidatesTags: ["exploreArtists"],
    }),
    getOwners: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) =>
        `/owners:art?pageNo=${pageNo}&pageSize=${pageSize}${
          filters?.name ? `&filters[firstName]=${filters?.name}` : ""
        }`,
      providesTags: ["exploreOwners"],
    }),
    getHomeApi: builder.query<any, any>({
      query: ({ domain }) => ({
        url: `/home`,
        headers: {
          "x-domain-name": domain,
        },
      }),
      providesTags: ["HomeApi"],
    }),
    // /products/{productId}
    getSingleArt: builder.query<any, any>({
      query: ({ artId }) => `/products/${artId}`,
      providesTags: ["SingleArt"],
    }),
    getContests: builder.query({
      query: () => `/contests`,
      transformResponse: (response: any) => response?.data,
      providesTags: ["contests"],
    }),
  }),
});
export const ProfileDetail = createApi({
  reducerPath: "UserProfile",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ARTOGENIA_API,
  }),
  refetchOnReconnect: true,
  tagTypes: ["profile", "profileArts"],
  endpoints: (builder) => ({
    getProfileDetail: builder.query({
      query: ({ identifier }: { identifier: string }) =>
        identifier ? `/artists/${identifier}` : "",
      providesTags: ["profile"],
    }),
    getProfileArts: builder.mutation({
      query: ({ filter, pageNo, pageSize }) => {
        var body: any = {
          pageNo,
          pageSize,
          filter,
        };
        return {
          url: `/products/fetch`,
          method: "POST",
          body,
        };
      },

      invalidatesTags: ["profileArts"],
    }),
  }),
});
export const ProtectAPIs = createApi({
  reducerPath: "ProtectAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ARTOGENIA_API,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getAccessToken(getState()); // Get the access token
      if (accessToken) {
        // Add the access token to the headers
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "getUserLocation",
    "paymentHistory",
    "tokenPaymentList",
    "paymentOrderInfo",
    "WishList",
  ],
  endpoints: (builder) => ({
    getUserLocation: builder.query({
      query: ({ perPage }) => `/profile/locations?pageSize=${perPage}`,
      providesTags: ["getUserLocation"],
    }),
    getPaymentHistory: builder.query({
      query: () => `/balance/payment/histories?sort=-id&pageSize=50`,
      providesTags: ["paymentHistory"],
    }),
    getPaymentTokenList: builder.query({
      query: () => `/balance/payment/currencies`,
      providesTags: ["tokenPaymentList"],
    }),
    getOrderInfo: builder.query({
      query: ({ orderId }) => `/balance/payment/orders/${orderId}`,
      providesTags: ["paymentOrderInfo"],
    }),
    getWishList: builder.query({
      query: () => `/profile/wishlist`,
      providesTags: ["WishList"],
    }),
  }),
});
export const nftAcount = createApi({
  reducerPath: "NFTs",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.rarible.org/v0.1/items/byOwner",
    prepareHeaders: (headers) => {
      // Set the X-API-KEY header
      headers.set("X-API-KEY", "a84bd16f-8e4f-4bad-a9ee-fa6a87053e81");
      return headers;
    },
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getNFTs: builder.query({
      query: ({ walletAddress }) =>
        `/?blockchains=POLYGON&owner=ETHEREUM:${walletAddress}&size=500`,
    }),
    // other API 2 endpoints...
  }),
});
export const SearchApi = createApi({
  reducerPath: "search",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_ARTOGENIA_API }),
  tagTypes: ["searchValue"],
  endpoints: (builder) => ({
    getSearch: builder.query<any, any>({
      query: ({ filter, pageSize, pageNo, searchValue }) =>
        // SF_ALL
        `/search?q=${searchValue}&filter=${filter}${
          filter !== "SF_ALL" ? `&pageSize=${pageSize}&pageNo=${pageNo}` : ""
        }`,
      providesTags: ["searchValue"],
    }),
  }),
});

export const LocationApi = createApi({
  reducerPath: "Location",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_ARTOGENIA_API }),
  tagTypes: ["countries", "states", "cities"],
  endpoints: (builder) => ({
    getCountries: builder.query<any, any>({
      query: ({ pageSize, searchValue }) =>
        `/countries?pageSize=${pageSize}&${
          searchValue ? `filters[name]=${searchValue}` : ""
        }`,
      providesTags: ["countries"],
    }),
    getStates: builder.query<any, any>({
      query: ({ pageSize, searchValue, countryId }) =>
        `/states?pageSize=${pageSize}&${
          countryId ? `filters[countryId]=${countryId}` : ""
        }&${searchValue ? `filters[name]=${searchValue}` : ""}`,
      providesTags: ["states"],
    }),
    getCities: builder.query<any, any>({
      query: ({ pageSize, searchValue, stateId }) =>
        `/cities?pageSize=${pageSize}&${
          stateId ? `filters[stateId]=${stateId}` : ""
        }&${searchValue ? `filters[name]=${searchValue}` : ""}`,
      providesTags: ["cities"],
    }),
  }),
});

export const kucoinPriceCoinApi = createApi({
  reducerPath: "kucoin",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.kucoin.com",
    prepareHeaders: (headers) => {
      // Set the X-API-KEY header
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),

  tagTypes: ["prices"],
  endpoints: (builder) => ({
    getCoinPrices: builder.query<any, any>({
      query: () => `/api/v1/prices?currencies=MATIC,USDT,BTC,ETH`,

      providesTags: ["prices"],
    }),
  }),
});
export const {
  useGetArtsMutation,
  useGetSingleArtQuery,
  useGetArtistsMutation,
  useGetHomeApiQuery,
  useGetOwnersQuery,
  useGetContestsQuery,
} = Api;
export const { useGetNFTsQuery } = nftAcount;
export const { useGetSearchQuery } = SearchApi;
export const { useGetProfileDetailQuery, useGetProfileArtsMutation } =
  ProfileDetail;
export const {
  useGetPaymentHistoryQuery,
  useGetOrderInfoQuery,
  useGetPaymentTokenListQuery,
  useGetUserLocationQuery,
  useGetWishListQuery,
} = ProtectAPIs;
export const { useGetCitiesQuery, useGetCountriesQuery, useGetStatesQuery } =
  LocationApi;
export const { useGetCoinPricesQuery } = kucoinPriceCoinApi;
