export enum RouteEnum {
  home = "/",
  login = "/auth/login",
  walletConnected = "/connect-wallet",
  register = "/auth/register",
  forgotPassword = "/forgot-password",
  dashboard = "/dashboard",
  activity = "/dashboard/activity",
  exploreIp = "/dashboard/exploreIp",
  savedIp = "/dashboard/saved-ip",
  accountPreferences = "/setting/account-preferences",
  contactInfo = "/setting/contact-info",
  pendingForActivation = "/pendingForActivation",
  authenticationResult = "/authenticationResult",
  generalPreferences = "/setting/general-preferences",
  profile = "/profile/",
  mint = "/mint",
  whitePaper = "/",
  aboutUs = "/about-us",
  faq = "/faq",
  tos = "/tos",
  privacy = "/privacy",
  searchResult = "/search",
  publicIpRoute = "/ip/:ipDetail",
  publicIp = "/ip/",
  news = "/news/:newsid",
  setting = "/setting/general-preferences",
}

export enum AuthEnum {
  accessToken = "accessToken",
  userId = "userId",
  refreshToken = "refreshToken",
  accessTokenExpiration = "accessTokenExpiration",
  referralCode = "referralCode",
}

export enum operationEnum {
  mint = "BT_MINT_ITEM",
  createFixedPrice = "BT_CREATE_LIST_FOR_SALE_FIXED_PRICE",
  cancelFixedPrice = "BT_CANCEL_LIST_FOR_SALE_FIXED_PRICE",
  buyFixedPrice = "BT_SOLD_LIST_FOR_SALE_FIXED_PRICE",
  createAuction = "BT_CREATE_LIST_FOR_SALE_AUCTION",
  cancelAuction = "BT_CANCEL_LIST_FOR_SALE_AUCTION",
  createBid = "BT_CREATE_BID",
}

export const listItemForSaleType = ["OIT_PHYSICAL", "OIT_DIGITAL"];
