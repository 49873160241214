export const contests = [
  {
    id: "668f02d0b755b136a9e1f6b7",
    name: "Art Genesis",
    type: "CT_GALLERY",
    visible: "CVT_PUBLIC",
  },
  {
    id: "66e5685d14f04b7bee191922",
    name: "photoacademy",
    type: "CT_GALLERY",
    visible: "CVT_PUBLIC",
  },
  {
    id: "66e6ffdcd33ccaa6fe93a64a",
    name: "First Painting Contest",
    type: "CT_CONTEST",
    visible: "CVT_PUBLIC",
  },
];

const categories = [
  // art
  {
    bg: "66bddda50c63be0b717760c8",
    icon: "66bddd6c0c63be0b717760c6",
    name: "Calligraphy",
    id: "65895e48e807adb89bb31286",
    domain: "art",
  },
  {
    bg: "66bddcf80c63be0b717760be",
    icon: "66bde51d0c63be0b717760de",
    name: "Marquetry",
    id: "65a663912981636b6a5201d7",
    domain: "art",
  },
  {
    bg: "66bddb000c63be0b717760b0",
    icon: "66bdd90b0c63be0b717760ae",
    name: "Photography",
    id: "65a663d02981636b6a5201dc",
    domain: "art",
  },
  {
    bg: "66bddd490c63be0b717760c4",
    icon: "66bde4e30c63be0b717760dc",
    name: "Digital Art",
    id: "65a663dc2981636b6a5201dd",
    domain: "art",
  },
  {
    bg: "66bddca40c63be0b717760ba",
    icon: "66bddc570c63be0b717760b8",
    name: "Miniature",
    id: "65a663212981636b6a5201d5",
    domain: "art",
  },

  {
    bg: "66bddb9f0c63be0b717760b2",
    icon: "66bddd1e0c63be0b717760c0",
    name: "Painting",
    id: "65895e2ce807adb89bb31285",
    domain: "art",
  },
  // antique
  {
    id: "662cf00702533968f0f6317c",
    name: "Vintage Fashion and Textiles",
    description: "",
    icon: "",
    bg: "66be02bb0c63be0b717760fc",
    domain: "antique",
  },
  {
    id: "662cf00702533968f0f6317b",
    name: "Ethnographica and Tribal Art",
    description: "",
    icon: "",
    bg: "66be02220c63be0b717760f2",
    domain: "antique",
  },
  {
    id: "662cf00702533968f0f6317a",
    name: "Persian, Greek, Egyption and other Antiquities",
    description: "",
    icon: "",
    bg: "66be02910c63be0b717760fa",
    domain: "antique",
  },
  {
    id: "662cf00702533968f0f63179",
    name: "Furniture",
    description: "",
    icon: "",
    bg: "66be02770c63be0b717760f6",
    domain: "antique",
  },
  {
    id: "662cf00702533968f0f63178",
    name: "Fine Art",
    description: "",
    icon: "",
    bg: "66be024e0c63be0b717760f4",
    domain: "antique",
  },
  {
    id: "662cf00702533968f0f63177",
    name: "Decorative Art",
    description: "",
    icon: "",
    bg: "66be02080c63be0b717760f0",
    domain: "antique",
  },
  {
    id: "662cf00702533968f0f63176",
    name: "Collectibles",
    description: "",
    icon: "",
    bg: "66be01e30c63be0b717760ee",
    domain: "antique",
  },
  {
    id: "662cf00702533968f0f63175",
    name: "Clocks, Watches and Jewellery",
    description: "",
    icon: "",
    bg: "66be02d40c63be0b717760fe",
    domain: "antique",
  },
  // handcraft
  {
    bg: "66bdd7ab0c63be0b717760aa",
    // icon: "66bdd8d30c63be0b717760ac",
    name: "Sculpture",
    id: "65a6639c2981636b6a5201d8",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5309",
    name: "Instrument Making",
    description: "",
    icon: "",
    bg: "66bde2580c63be0b717760d6",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5308",
    name: "Soap Making",
    description: "",
    icon: "",
    bg: "66bde1dd0c63be0b717760d0",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5307",
    name: "Basket Weaving",
    description: "",
    icon: "",
    bg: "66bde2a80c63be0b717760da",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5306",
    name: "Paper and Bookbinding",
    description: "",
    icon: "",
    bg: "66bde2150c63be0b717760d2",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5305",
    name: "Ceramics and Glasswork",
    description: "",
    icon: "",
    bg: "66bde2850c63be0b717760d8",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5304",
    name: "Woodwork",
    description: "",
    icon: "",
    bg: "66bde0440c63be0b717760cc",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5303",
    name: "Metalwork",
    description: "",
    icon: "",
    bg: "66bde23d0c63be0b717760d4",
    domain: "handcraft",
  },
  {
    id: "6628ed6795c8a6789eec5302",
    name: "Textile Arts",
    description: "",
    icon: "",
    bg: "66bde1230c63be0b717760ce",
    domain: "handcraft",
  },
  // carpet
  {
    id: "6628f16795c8a6789eec5311",
    name: "Vintage Rugs",
    description: "",
    icon: "",
    bg: "66bde7280c63be0b717760ec",
    domain: "carpet",
  },
  {
    id: "6628f16795c8a6789eec5310",
    name: "Transitional Rugs",
    description: "",
    icon: "",
    bg: "66bde7130c63be0b717760ea",
    domain: "carpet",
  },
  {
    id: "6628f16795c8a6789eec530f",
    name: "Contemporary Rugs",
    description: "",
    icon: "",
    bg: "66bde6450c63be0b717760e0",
    domain: "carpet",
  },
  {
    id: "6628f16795c8a6789eec530e",
    name: "Traditional Rugs",
    description: "",
    icon: "",
    bg: "66bde6bf0c63be0b717760e6",
    domain: "carpet",
  },
  {
    id: "6628f16795c8a6789eec530d",
    name: "Cotton Carpets",
    description: "",
    icon: "",
    bg: "66bde65f0c63be0b717760e2",
    domain: "carpet",
  },
  {
    id: "6628f16795c8a6789eec530c",
    name: "Wool Carpets",
    description: "",
    icon: "",
    bg: "66bde6ee0c63be0b717760e8",
    domain: "carpet",
  },
  {
    id: "6628f16795c8a6789eec530b",
    name: "Silk Carpets",
    description: "",
    icon: "",
    bg: "66bde68c0c63be0b717760e4",
    domain: "carpet",
  },
  {
    id: "668905c75432761c1441e25f",
    name: "Embossed Hand-Woven",
    description: "",
    icon: "",
    bg: "66bde68c0c63be0b717760e4",
    domain: "carpet",
  },

  {
    id: "663a0ab4f080ccc27150349a",
    name: "Brooches & Pins",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "663a0ab4f080ccc271503499",
    name: "Crowns & Tiaras",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "663a0ab4f080ccc271503498",
    name: "Bangles",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "663a0ab4f080ccc271503497",
    name: "Anklets",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "6638af44ab3124da755d2eb7",
    name: "Necklaces",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "6638af44ab3124da755d2eb6",
    name: "Earrings",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "6638af44ab3124da755d2eb5",
    name: "Rings",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "6638af44ab3124da755d2eb4",
    name: "Bracelets",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "6638af44ab3124da755d2eb3",
    name: "Sets",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
  {
    id: "664b652f296b653c5a6fe291",
    name: "Stones",
    description: "",
    bg: "",
    icon: "",
    domain: "jewellery",
  },
];
const filterCategoriesByDomain = (domain: string) => {
  return categories.filter((category) => category.domain === domain);
};
export { filterCategoriesByDomain };
