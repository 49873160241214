import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authReducer from "./auth/authSlice";
import {
  Api,
  SearchApi,
  nftAcount,
  ProfileDetail,
  LocationApi,
  kucoinPriceCoinApi,
  ProtectAPIs
} from "./api/api";
import { useDispatch } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [Api.reducerPath]: Api.reducer,
    [nftAcount.reducerPath]: nftAcount.reducer,
    [SearchApi.reducerPath]: SearchApi.reducer,
    [ProfileDetail.reducerPath]: ProfileDetail.reducer,
    [LocationApi.reducerPath]: LocationApi.reducer,
    [ProtectAPIs.reducerPath]: ProtectAPIs.reducer,
    [kucoinPriceCoinApi.reducerPath]: kucoinPriceCoinApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      Api.middleware,
      nftAcount.middleware,
      ProfileDetail.middleware,
      LocationApi.middleware,
      ProtectAPIs.middleware,
      SearchApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

setupListeners(store.dispatch);
