import amazonIcon from "./amazonIcon.webp";
import europeanCommission from "./european_commission.webp";
import gojdIcon from "./gojd-icon.webp";
import photoacademyLogo from "./photoacademy-logo.webp";
import sapLogo from "./sap-logo.webp";
import techstars from "./techstars.webp";
import aftaLogo from "./afta-logo.webp";
export const JournalIcons = [
  { name: "amazonIcon", logo: amazonIcon },
  { name: "european commission", logo: europeanCommission },
  { name: "gojd", logo: gojdIcon },
  { name: "photoacademy", logo: photoacademyLogo },
  { name: "SAP", logo: sapLogo },
  { name: "techstars", logo: techstars },
  { name: "americans for the arts", logo: aftaLogo },
];
