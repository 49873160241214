import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/authSlice";
import { biconomyContext } from "../context";
import { jwtDecode } from "jwt-decode";
import guardedRoutes from "./guardedRoutes";

const Guard = () => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const accessTokenExpiration =accessToken? jwtDecode(`${accessToken}`)?.exp:0;
  const currentTime = Math.floor(Date.now() / 1000);
  const dispacth = useDispatch();
  const pathname = useLocation().pathname;
  const nav = useNavigate();
  const { particle } = useContext(biconomyContext);
  const isGuardedRoute = guardedRoutes.some((route) =>
    pathname.startsWith(route)
  );

  const particleLogout = async () => {
    await particle.auth.logout();
  };
  useEffect(() => {
    if (
      (isGuardedRoute && !accessToken) ||
      (isGuardedRoute && !particle.auth.isLogin())
    ) {
      nav("/");
      dispacth(logout());
      particleLogout();
    }
    if (accessToken && accessTokenExpiration < currentTime) {
      dispacth(logout());
      particleLogout();
      if (isGuardedRoute) {
        nav("/");
      }
    }

    if (accessToken && !particle.auth.isLogin()) {

      dispacth(logout());
    }
    // if (!accessToken && particle.auth.isLogin()) {
    //   dispacth(logout());
    //   particleLogout();
    // }
  }, [accessToken, pathname, particle.auth.isLogin()]);

  return null;
};

export default Guard;
